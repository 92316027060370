import React, { useEffect, useState } from "react";
import AnuncioListItem from "../AnuncioListItem/AnuncioListItem";
import { FormattedMessage } from "react-intl";
import "./anuncios-list.scss";

import { AnuncioResumido } from "../types";


type Props = {
  isLoading: boolean;
  showEmptyTextMessage?: boolean;
  anuncios?: AnuncioResumido[];
  anuncioShowUrl: string;
}


export function AnunciosList({ isLoading, showEmptyTextMessage = true, anuncios, anuncioShowUrl }: Props): JSX.Element {
  if (isLoading) {
    return <div className="lista">
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
        return <AnuncioListItem key={item} isLoading={true} />
      })}
    </div>
  }

  const showEmptyText = ()=>{
    if(showEmptyTextMessage){
      return (
        (
          <div className="nenhum-evento">
            {/* <EmptyIcon /> */}
            <h3><FormattedMessage id='nenhum_resultado_encontrado' /></h3>
            <p><FormattedMessage id='descricao_nenhum_resultado' /></p>
            <a href="/"><FormattedMessage id='explorar_eventos' /></a>
          </div>
        )
      )
    } else {
      return
    }
  }
  
  return (
    <div>
      {anuncios && anuncios.length > 0 ? (
        <div className="lista">
          {anuncios.map((anuncio) => (
            <AnuncioListItem anuncioShowUrl={anuncioShowUrl} key={anuncio.id} anuncio={anuncio} />
          ))}
        </div>
      ) : showEmptyText()
      }
    </div >
  );
}