import { useQuery } from "@tanstack/react-query";
import { Banner, BannersCarouselApi } from "../types"
import getFriendlyErrorMessage from "src/helpers/getFriendlyErrorMessage";

type UseBannersResult = {
    banners?: Banner[];
    isLoadingBanners: boolean;
    errorLoadingBanners: string | undefined;
    reloadBanners(): void;
}

type UseBannersParams = {
    api: BannersCarouselApi
}

export function useBanners({ api }: UseBannersParams): UseBannersResult {
    const { data, isLoading, refetch, error } = useQuery({
        queryKey: ['banners'],
        queryFn() {
            return api.fetchBanners();
        }
    });


    return {
        banners: data,
        errorLoadingBanners: error ? getFriendlyErrorMessage(error, "Houve um erro ao carregar os banners") : undefined,
        isLoadingBanners: isLoading,
        reloadBanners: refetch,
    }
}