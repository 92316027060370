import React, { useEffect, useState } from "react";

import { PontoDeVendasModal } from "../ponto-de-vendas-modal/PontoDeVendasModal";
import { FormattedMessage } from "react-intl";

//@ts-ignore
import Icons from "../../assets/images/icons.svg";
import { usePontosDeVenda } from "./usePontosDeVenda";

type Props = {
  anuncioId: Number
}

export function BotaoPontoDeVendas({
  anuncioId
}: Props): JSX.Element {

  const [isOpen, setIsOpen] = useState<boolean>(false);

  function abrirModal() {
    setIsOpen(true);
  }

  function fecharModal() {
    setIsOpen(false);
  }

  return (
    <>
      <span className="link-button" onClick={abrirModal}>
        <svg>
          <use href={`${Icons}#map`}></use>
        </svg>
        <span><FormattedMessage id="ver_pdv" /></span>

      </span>
      {isOpen &&

        <PontoDeVendasModal
          anuncioId={anuncioId}
          isOpen={isOpen}
          onRequestClose={fecharModal}
        />
      }

    </>
  )
}