import React from "react";
import { AnuncioResumido } from "../types"
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { isSameMonth, isSameYear, parseJSON } from "date-fns";
import { FormattedDateParts, useIntl } from 'react-intl';

import "./anuncio-date.scss";

type Props = {
    anuncio?: AnuncioResumido;
    isLoading: boolean;
}

type Modelo = 'mesmo_dia' | 'mesmo_mes_e_ano' | 'mesmo_ano' | 'tudo_diferente';

export function AnuncioDate({ anuncio, isLoading }: Props) {
    if (isLoading) {
        return (
            <div className="data">
                <span className="dia"><Skeleton /></span>
                <span className="mes"><Skeleton /></span>
            </div>
        )
    }

    if (!anuncio) return null;

    const exibirFim = anuncio.multiplos_dias;

    const inicio = parseJSON(anuncio.data_inicio_no_fuso_da_cidade);
    let fim: Date | undefined;
    if (anuncio.data_fim_no_fuso_da_cidade) {
        fim = parseJSON(anuncio.data_fim_no_fuso_da_cidade);
    } else {
        fim = inicio;
    }

    const mesesDiferentes = !isSameMonth(inicio, fim);
    const anosDiferentes = !isSameYear(inicio, fim);

    let modelo: Modelo = 'mesmo_dia';

    if (anuncio.multiplos_dias) {
        modelo = 'mesmo_mes_e_ano';

        if (mesesDiferentes && !anosDiferentes) {
            modelo = 'mesmo_ano';
        } else if (anosDiferentes) {
            modelo = 'tudo_diferente';
        }
    }

    const { locale } = useIntl();

    //MODELO QUANDO É TUDO NO MESMO DIA DE EVENTO
    if (modelo === 'mesmo_dia') {
        return (
            <FormattedDateParts
                value={inicio}
                month="short"
                day="2-digit"
            >
                {parts => {
                    const dayPart = parts.find(part => part.type === 'day');
                    const monthPart = parts.find(part => part.type === 'month');

                    if (!dayPart || !monthPart) return null;

                    return (
                        <div className='data'>
                            {locale === 'pt-BR' ? (
                                <>
                                    <span className="dia">{dayPart.value}</span>
                                    <span className="mes">{monthPart.value.replace('.', '')}</span>
                                </>
                            ) : (
                                <>
                                    <span className="mes">{monthPart.value.replace('.', '')}</span>
                                    <span className="dia">{dayPart.value}</span>
                                </>
                            )}
                        </div>
                    );
                }}
            </FormattedDateParts>
        );
    }

    //MODELO PARA QUANDO POSSUI O MESMO MÊS MAS SÃO DIAS DIFERENTES
    if (modelo === 'mesmo_mes_e_ano') {
        return (
            <div className={`data data-ingresso mesmo_mes_e_ano ${locale}`}>
                {locale === 'pt-BR' ? (
                    <>
                        <FormattedDateParts value={inicio} day="2-digit">
                            {parts => {
                                const dayPart = parts.find(part => part.type === 'day');
                                return dayPart ? <span className="dia">{dayPart.value}</span> : null;
                            }}
                        </FormattedDateParts>
                        <FormattedDateParts value={fim} day="2-digit">
                            {parts => {
                                const dayPart = parts.find(part => part.type === 'day');
                                return dayPart ? <span className="dia">{dayPart.value}</span> : null;
                            }}
                        </FormattedDateParts>
                        <FormattedDateParts value={inicio} month="short">
                            {parts => {
                                const monthPart = parts.find(part => part.type === 'month');
                                return monthPart ? <span className="mes">{monthPart.value.replace('.', '')}</span> : null;
                            }}
                        </FormattedDateParts>
                    </>
                ) : (
                    <>
                        <FormattedDateParts value={inicio} month="short">
                            {parts => {
                                const monthPart = parts.find(part => part.type === 'month');
                                return monthPart ? <span className="mes">{monthPart.value.replace('.', '')}</span> : null;
                            }}
                        </FormattedDateParts>
                        <FormattedDateParts value={inicio} day="2-digit">
                            {parts => {
                                const dayPart = parts.find(part => part.type === 'day');
                                return dayPart ? <span className="dia">{dayPart.value}</span> : null;
                            }}
                        </FormattedDateParts>
                        <FormattedDateParts value={fim} day="2-digit">
                            {parts => {
                                const dayPart = parts.find(part => part.type === 'day');
                                return dayPart ? <span className="dia">{dayPart.value}</span> : null;
                            }}
                        </FormattedDateParts>
                    </>
                )}
            </div>
        );
    }

    //MODELO PARA QUANDO SÃO MESES DIFERENTES MAS POSSUI O MESMO ANO
    if (modelo === 'mesmo_ano') {
        return (
            <div className={`data data-ingresso mesmo_ano meses_diferentes ${locale}`}>
                {locale === 'pt-BR' ? (
                    <>
                        <FormattedDateParts value={inicio} day="2-digit">
                            {parts => {
                                const dayPart = parts.find(part => part.type === 'day');
                                return dayPart ? <span className="dia">{dayPart.value}</span> : null;
                            }}
                        </FormattedDateParts>
                        <FormattedDateParts value={inicio} month="short">
                            {parts => {
                                const monthPart = parts.find(part => part.type === 'month');
                                return monthPart ? <span className="mes">{monthPart.value.replace('.', '')}</span> : null;
                            }}
                        </FormattedDateParts>
                        <FormattedDateParts value={fim} day="2-digit">
                            {parts => {
                                const dayPart = parts.find(part => part.type === 'day');
                                return dayPart ? <span className="dia">{dayPart.value}</span> : null;
                            }}
                        </FormattedDateParts>
                        <FormattedDateParts value={fim} month="short" year="numeric">
                            {parts => {
                                const monthPart = parts.find(part => part.type === 'month');
                                const yearPart = parts.find(part => part.type === 'year');
                                return (
                                    <>
                                        {monthPart && <span className="mes">{monthPart.value.replace('.', '')}</span>}
                                        {yearPart && <span className="ano">{yearPart.value}</span>}
                                    </>
                                );
                            }}
                        </FormattedDateParts>
                    </>
                ) : (
                    <>
                        <FormattedDateParts value={inicio} month="short">
                            {parts => {
                                const monthPart = parts.find(part => part.type === 'month');
                                return monthPart ? <span className="mes">{monthPart.value.replace('.', '')}</span> : null;
                            }}
                        </FormattedDateParts>
                        <FormattedDateParts value={inicio} day="2-digit">
                            {parts => {
                                const dayPart = parts.find(part => part.type === 'day');
                                return dayPart ? <span className="dia">{dayPart.value}</span> : null;
                            }}
                        </FormattedDateParts>
                        <FormattedDateParts value={fim} month="short">
                            {parts => {
                                const monthPart = parts.find(part => part.type === 'month');
                                return monthPart ? <span className="mes">{monthPart.value.replace('.', '')}</span> : null;
                            }}
                        </FormattedDateParts>
                        <FormattedDateParts value={fim} day="2-digit">
                            {parts => {
                                const dayPart = parts.find(part => part.type === 'day');
                                return dayPart ? <span className="dia">{dayPart.value}</span> : null;
                            }}
                        </FormattedDateParts>
                        <FormattedDateParts value={fim} year="numeric">
                            {parts => {
                                const yearPart = parts.find(part => part.type === 'year');
                                return yearPart ? <span className="ano">{yearPart.value}</span> : null;
                            }}
                        </FormattedDateParts>
                    </>
                )}
            </div>
        );
    }


    if (modelo == 'tudo_diferente') { 
        return (
            <div className="data data-ingresso tudo_diferente meses_diferentes">
                <FormattedDateParts
                    value={inicio}
                    day="2-digit"
                >
                    {parts => {
                        return (
                            <span className="dia">{parts[0].value}</span>
                        )
                    }}
                </FormattedDateParts>
                <FormattedDateParts
                    value={inicio}
                    month="short"
                    day="2-digit"
                    year="numeric"
                >
                    {parts => {
                        return (
                            <>
                                <span className="mes">{parts[2].value?.replace('.', '')}</span>
                                <span className="ano">{parts[4].value}</span>
                            </>
                        )
                    }}
                </FormattedDateParts>
                <FormattedDateParts
                    value={fim}
                    day="2-digit"
                >
                    {parts => {
                        return (
                            <span className="dia">{parts[0].value}</span>
                        )
                    }}
                </FormattedDateParts>
                <FormattedDateParts
                    value={fim}
                    month="short"
                    day="2-digit"
                    year="numeric"
                >
                    {parts => {
                        return (
                            <>
                                <span className="mes">{parts[2].value?.replace('.', '')}</span>
                                <span className="ano">{parts[4].value}</span>
                            </>
                        )
                    }}
                </FormattedDateParts>
            </div>
        )
    }

    return null;
}