import React, { useState } from "react";
import Modal from "react-modal";

//@ts-ignore
import Icons from "../../../assets/images/icons.svg";

import './location-picker-modal.scss';
import { useCities } from "../services/useCities";
import { Pais, Region } from "../types";
import { FormattedMessage } from "react-intl";

type Props = {
    isOpen: boolean;
    onRequestClose?(): any;
    selectRegion(region: Region | undefined): any;
    getCurrentPosition(): any;
    paises: Pais[];
    currentRegion?: Region;
}

//https://reactcommunity.org/react-modal/styles/
const styles = {
    content: {
        width: '40%',
        height: 'fit-content',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.65 )',
    }
}

export default function LocationPickerModal({
    isOpen,
    onRequestClose,
    selectRegion,
    getCurrentPosition,
    paises,
    currentRegion,
}: Props): JSX.Element {
    const [paisId, setPaisId] = useState<number | undefined>(currentRegion?.id);
    const [estadoId, setEstadoId] = useState<number | undefined>(currentRegion?.estado?.id);

    const pais = paisId ? paises.find(p => p.id == paisId) : undefined;
    const estado = estadoId && pais ? pais?.estados?.find((estado) => estado.id == estadoId) : undefined;

    const onChangePais = (event: any) => {
        setPaisId(event.target.value);
        setEstadoId(undefined);
    }

    const onChangeEstado = (event: any) => {
        setEstadoId(event.target.value);
    }

    const isButtonEnabled = !!(paisId) || (!paisId && !estadoId)

    const onButtonClick = () => {
        if(isButtonEnabled) {
            let region: Region | undefined = undefined;
            if(pais) {
                region = {
                    id: pais.id,
                    nome: pais.nome,
                    codigo: pais.codigo,
                    estado: estado ? {
                        id: estado.id,
                        nome: estado.nome,
                        nome_com_pais: estado.nome_com_pais,
                        sigla: estado.sigla,
                    } : undefined
                }
            }
            selectRegion(region);
        }
    }

    return (
        <Modal
            onRequestClose={onRequestClose}
            isOpen={isOpen}
            style={styles}
            className={"modal-info"}
        >
            <div id="modal-localizacao">
                <div className="modal">
                    <div className="header">
                        <div className="titulo">
                            <span><FormattedMessage id='localizacao'/></span>
                            <span><FormattedMessage id='experiencia_personalizada'/></span>
                        </div>
                        <a className="fechar" onClick={onRequestClose}>
                            <svg>
                                <use href={`${Icons}#fechar`} />
                            </svg>
                        </a>
                    </div>

                    <div className="select">
                        <label><FormattedMessage id='pais'/>:</label>
                        <select onChange={onChangePais} value={paisId}>
                            <option value={''}><FormattedMessage id='todos'/></option>
                            {paises.map((pais) => {
                                return <option value={pais.id} key={`pais_${pais.id}`}>{pais.nome}</option>
                            })}
                        </select>
                    </div>

                    <div className="select">
                        <label><FormattedMessage id='estado'/>:</label>
                        <select value={estadoId} onChange={onChangeEstado}>
                            <option value={''}><FormattedMessage id='todos'/></option>
                            {pais?.estados?.map((estado) => {
                                return <option value={estado.id} key={`estado_${estado.id}`}>{estado.nome}</option>
                            })}
                        </select>
                    </div>

                    <button disabled={!isButtonEnabled} onClick={onButtonClick}><FormattedMessage id='filtrar'/></button>
                </div>
            </div>
        </Modal>
    )
}