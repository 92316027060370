import React, { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { AnuncioResumido } from "../types";

import "./anuncio-list-item.scss";
import { AnuncioDate } from "../AnuncioDate";

import { LazyLoadImage, ScrollPosition, trackWindowScroll } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

type Props = {
    anuncio?: AnuncioResumido;
    isLoading?: boolean;
    anuncioShowUrl?: string;
    scrollPosition: ScrollPosition;
}

const AnuncioListItem = ({ anuncio, isLoading = false, anuncioShowUrl, scrollPosition }: Props): JSX.Element => {
    const desktopMode = window.outerWidth >= 600;
    let [url, setUrl] = useState<string>('');

    const [isErrorLoadingImage, setIsErrorLoadingImage] = useState<boolean>(false);

    const renderImage = () => {
        if (isErrorLoadingImage) {
            return <img src={require("../../../assets/images/stub.png")} />;
        }

        const placeHolder = <img src={require("../../../assets/images/stub.png")} />;

        return (
            <LazyLoadImage
                srcSet={desktopMode ? anuncio!.evento.foto_2_url : anuncio!.evento.foto_url}
                alt={`Banner do evento ${anuncio?.evento?.titulo}`}
                placeholder={placeHolder}
                effect="opacity"
                scrollPosition={scrollPosition}
                onError={() => setIsErrorLoadingImage(true)}
            />
        )
    }

    useEffect(() => {
        if (anuncio && anuncioShowUrl) {
            setUrl(anuncioShowUrl.replace('__ID__', anuncio.id.toString()).replace('__URI__', anuncio.uri))
        }
    }, [])


    return (
        <a className="card" href={url} >
            <div className="imagem">
                {isLoading ? <Skeleton height={'100%'} /> : renderImage()}
            </div>
            <AnuncioDate anuncio={anuncio} isLoading={isLoading} />
            <div className="info home">
                <span className="nome">{isLoading ? <Skeleton /> : anuncio!.evento.titulo}</span>
                <span className="cidade">{isLoading ? <Skeleton /> : anuncio!.evento.local.cidade_estado}</span>
                <span className="lugar">{isLoading ? <Skeleton /> : anuncio!.evento.local.nome_sem_cidade}</span>
            </div>
        </a>
    )
}

export default trackWindowScroll(AnuncioListItem);