import { AxiosError, isAxiosError } from "axios";

type Err = string | Error | AxiosError;

export function getFriendlyErrorMessage(err: Err): string | undefined;
export function getFriendlyErrorMessage(err: Err, defaultMessage: string): string;
export function getFriendlyErrorMessage(err: Err, defaultMessage?: string): unknown {
    let message: string | undefined;
    if (err instanceof Error) {
        if (isAxiosError(err) && err?.response?.data?.mensagem) {
            return err.response!.data.mensagem as string;
        } else {
            message = err.message
        }
    } else if (typeof err == 'string') {
        message = err
    }

    if (message && (/^Network Error/i.test(message) || /^timeout of 30000ms exceeded/i.test(message))) {
        return "Erro de conexão! Verifique se o aparelho está conectado a internet."
    }

    if (message) {
        return message;
    }

    return defaultMessage;
}