import { ContainerBase } from "containers/ContainerBase";
import React from "react";

import { LocationIndicator } from "../components/location/LocationIndicator";
import { Pais, Region } from "../components/location/types";

type Props = {
    region?: Region;
    paises: Pais[];
}

export function LocationIndicatorContainer(props: Props, railsContext: any) {
    return () => {
        return (
            <ContainerBase railsContext={railsContext}>
                <LocationIndicator 
                    region={props.region} 
                    paises={props.paises} 
                />
            </ContainerBase>
        );
    }
}