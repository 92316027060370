import React from "react";
import { useAnuncios } from "./services/useAnuncios";
import { AnunciosList } from "./AnunciosList";
import { Region } from "components/location/types";

type Props = {
  termoBusca?: string;
  region?: Region;
  anuncioShowUrl: string;
}

export function AnunciosListLoader({ termoBusca, region, anuncioShowUrl }: Props) {
  const { anuncios, isLoading} = useAnuncios({
    paisCodigo: region?.codigo,
    estadoSigla: region?.estado?.sigla,
    termoBusca
  });

  return (
    <AnunciosList
      anuncios={anuncios}
      isLoading={isLoading}
      anuncioShowUrl={anuncioShowUrl}
    />
  );
}