import React from "react";
import { ContainerBase } from "containers/ContainerBase";
import { MenuLateralAnuncio } from "../components/menu-lateral-anuncio";


export function MenuLateralAnuncioContainer(props: any, railsContext: any) {
    return () => {
        return (
            <ContainerBase railsContext={railsContext}>
                <MenuLateralAnuncio />
            </ContainerBase>
        )
    }
}