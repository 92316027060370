import { isAfter, parseJSON } from "date-fns";

type SetItemOptions = {
    expirationDate?: Date;
}

export function setItem(key: string, value: string, options: SetItemOptions): void {
    const storedValue = {
        value,
        expirationDate: options.expirationDate,
    }

    localStorage.setItem(key, JSON.stringify(
        storedValue
    ));
}

export function getItem<T>(key: string): T | undefined {
    const storedValue = localStorage.getItem(key);

    if(!storedValue) return;

    const parsedValue = JSON.parse(storedValue);
    if(!parsedValue.expirationDate) return parsedValue;

    const expirationDate = parseJSON(parsedValue.expirationDate);
    if(expirationDate && isAfter(expirationDate, new Date())) {
        return parsedValue.value;
    }

    return undefined;
}