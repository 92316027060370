import { useEffect, useState, useRef } from "react";
import { format } from "date-fns";
import { clearInterval, clearTimeout, setInterval, setTimeout } from 'worker-timers';

type Params = {
  timerInSeconds: number | (()=> number),
  secondsLeft?: number | (()=> number),
  onTimerOver: Function
}

type PeriodoTempo = {
  segundos: number,
  minutos: number,
  horas: number,
  dias: number
}

type Result = {
  timerString: String,
  timerSemLabelString: String,
  periodObj: PeriodoTempo,
  widthPercentageLeft: number
}

export default function useTimer({timerInSeconds, secondsLeft = 0, onTimerOver}: Params): Result{
  const [totalSeconds, _setTotalSeconds] = useState<number>(timerInSeconds);
  const [timer, setTimer] = useState<number>(secondsLeft);
  
  const intervalRef = useRef(0)

  // diminui o timer
  const decreaseTimer = (): void => {
    setTimer(timer => timer - 1);
  }

  // Gera um loop de 1 segundo e diminui o timer
  useEffect(() => {
    const interval = setInterval(decreaseTimer, 1000);
    intervalRef.current = interval

    return () => clearInterval(interval); 
  },[])

  // Monitora o timer e redireciona quando concluir
  useEffect(() => {
    if (timer <= 0) {
      const interval = intervalRef.current;
      clearInterval(interval)
      
      onTimerOver()
    }
  }, [timer])

  const gerarPeriodoTempo = (segundosTotais: number): PeriodoTempo => {
    let minutosAux = Math.floor(segundosTotais / 60)
    let horasAux = Math.floor(minutosAux / 60)

    let periodoTempo: PeriodoTempo = {
      segundos: Math.floor(segundosTotais % 60),
      minutos: Math.floor(minutosAux % 60),
      horas: Math.floor(horasAux % 24),
      dias: Math.floor(horasAux / 24)
    };
    return periodoTempo
  }

  const gerarLabelString = (segundosTotais: number): string => {
    let periodoTempo: PeriodoTempo = gerarPeriodoTempo(segundosTotais)

    const diasLabel: string = periodoTempo.dias <= 0 ? '' : `${format(new Date(0, 0, periodoTempo.dias), 'dd')} dias `
    const horasLabel: string = periodoTempo.horas <= 0 ? '' : `${format(new Date(0, 0, 0, periodoTempo.horas), 'HH')} horas `
    const minutosLabel: string = periodoTempo.minutos <= 0 ? '' : `${format(new Date(0, 0, 0, 0, periodoTempo.minutos), 'mm')} minutos `

    return diasLabel + horasLabel + minutosLabel + `${format(new Date(0, 0, 0, 0, 0, periodoTempo.segundos), 'ss')} segundos`
  }

  const gerarTimerSemLabelString = (segundosTotais: number): string => {
    let periodoTempo: PeriodoTempo = gerarPeriodoTempo(segundosTotais)

    let horas = (periodoTempo.dias * 24) + periodoTempo.horas
    let label = ''
    
    if (horas > 0) {
      label = horas + ':' 
    }

    label += periodoTempo.minutos.toString().padStart(2,'0') + ':' + periodoTempo.segundos.toString().padStart(2,'0')
    
    return label
  }

  let result: Result = {
    timerString: gerarLabelString(timer),
    timerSemLabelString: gerarTimerSemLabelString(timer),
    periodObj: gerarPeriodoTempo(timer),
    widthPercentageLeft: 100 - (100 - ((timer / totalSeconds) * 100))
  }

  return result;
}