import axios from 'axios';
import { getDeviceId } from 'core/services/authentication/helpers';

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    common: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    post: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    put: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    patch: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  },
  withCredentials: true,
  timeout: 30000
});

instance.interceptors.request.use(
  async function (config) {
    const deviceId = getDeviceId();
    config.headers['dev'] = deviceId;
    return config;
  }, function (error) {
    return Promise.reject(error);
  }
)

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export default instance;