import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { Region } from "../types";
import { getSelectedRegionFromStorage, removeSelectedRegionFromStorage, saveSelectedRegionIntoStorage } from "../helpers/selectedRegion";
import { getRegionByCoordinates } from "./api/regioes";

enum LocationError {
    PERMISSION_DENIED = 1,
    POSITION_UNAVAILABLE = 2,
    TIMEOUT = 3,
    ERROR_GET_CITY_COORDINATES = 4,
    OTHER = 5,
}

const getCurrentPositionAsync = () => {
    return new Promise<GeolocationPosition>((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function onSuccess(position) {
                resolve(position);
            }, function onError(error) {
                console.warn("position error", error);
                reject(error);
            })
        } else {
            reject(LocationError.OTHER);
        }
    })
}

//TODO: TROCAR SELECTEDCITY POR REGIAO
export function useCurrentLocation() {
    const [locationError, setLocationError] = useState<GeolocationPositionError>()
    const [currentPosition, setCurrentPosition] = useState<GeolocationPosition>();
    const [selectedRegion, setSelectedRegion] = useState<Region | undefined>(getSelectedRegionFromStorage());

    const getCurrentPosition = useCallback(async () => {
        try {
            setLocationError(undefined);
            const position = await getCurrentPositionAsync();
            setCurrentPosition(position);
        } catch (err) {
            setLocationError(err as any as GeolocationPositionError);
            setCurrentPosition(undefined);
        }
    }, [navigator]);

    const { data: regionCurrentLocation, error: errorRegionCurrentLocation, isLoading: isLoadingRegionCurrentLocation } = useQuery({
        queryKey: ['get-cidade-by-coordinates', currentPosition?.coords],
        queryFn: async () => {
            setLocationError(undefined);
            if (!currentPosition) return;

            return getRegionByCoordinates({
                latitude: currentPosition.coords.latitude,
                longitude: currentPosition.coords.longitude
            })
        },
        enabled: !!currentPosition,
    });

    useEffect(() => {
        if (regionCurrentLocation) {
            setLocationError(undefined);
        }
    }, [regionCurrentLocation]);

    const selectRegionAndSave = (region: Region | undefined) => {
        if(!region) {
            setSelectedRegion(undefined);
            removeSelectedRegionFromStorage();
            return;
        }

        setSelectedRegion(region);
        saveSelectedRegionIntoStorage({ region });
    }

    return {
        getCurrentPosition,
        regionCurrentLocation,
        selectedRegion,
        locationError,
        selectRegionAndSave
    }
}