import { useEffect } from "react";
import { addLastSeenAnuncio } from "../helpers/storeLastSeenAnuncios";

type Props = {
    anuncioId?: number;
}

export function AddLastSeenAnuncio({ anuncioId }: Props) {
    useEffect(() => {
        if(anuncioId) {
            addLastSeenAnuncio({ anuncioId });
        }
    }, [anuncioId]);

    return null;
}