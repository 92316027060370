const LAST_SEEN_ANUNCIOS_IDS = 'LAST_SEEN_ANUNCIOS_IDS';

const LIMIT_LAST_SEEN_ANUNCIOS_STORED = 5; 

export function getLastSeenAnuncios(): number[] {
    try {
        let anunciosIds = localStorage.getItem(LAST_SEEN_ANUNCIOS_IDS);
        if(anunciosIds) {
            const parsedAnunciosIds = JSON.parse(anunciosIds);
            if(parsedAnunciosIds && parsedAnunciosIds.length > 0) {
                return parsedAnunciosIds.map((id: string) => parseInt(id));
            }
        }
    } catch(err) {
        console.error("getLastSeenAnuncios", err);
    }

    return [];
}


type AddLastSeenAnunciosParams = {
    anuncioId: number;
}

export function addLastSeenAnuncio({ anuncioId }: AddLastSeenAnunciosParams): void {
    try {
        let anunciosIds = getLastSeenAnuncios();

        if(anunciosIds && anunciosIds.length > 0) {
            if(anunciosIds.find((a) => a.toString() == anuncioId.toString())) {
                return;
            }
            if(anunciosIds.length >= LIMIT_LAST_SEEN_ANUNCIOS_STORED) {
                anunciosIds.splice(0, 1);
            }
            anunciosIds.push(anuncioId);
        } else {
            anunciosIds = [anuncioId];
        }

        setLastSeenAnuncios(anunciosIds);
    } catch(err) {
        console.error(err);
    }
}

export function setLastSeenAnuncios(anunciosIds: number[]): void {
    try {
        localStorage.setItem(LAST_SEEN_ANUNCIOS_IDS, JSON.stringify(anunciosIds));
    }catch(err) {
        console.error("setLastSeenAnuncios", err);
    }
}

export function removeLastSeenAnuncios(id: number): void {
    let anunciosIds = getLastSeenAnuncios();
    if(anunciosIds && anunciosIds.length > 0) {
        const index = anunciosIds.findIndex((anuncioId) => anuncioId.toString() == id.toString());

        if(index != -1) {
            anunciosIds.splice(index, 1);
           setLastSeenAnuncios(anunciosIds);
        }
    }
}

export function clearLastSeenAnuncios(): void {
    localStorage.removeItem(LAST_SEEN_ANUNCIOS_IDS)
}