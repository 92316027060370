import { AxiosError } from "axios";

type Err = string | Error | AxiosError | unknown;

function getFriendlyErrorMessage(err: Err): string | undefined;
function getFriendlyErrorMessage(err: Err, defaultMessage: string): string;
function getFriendlyErrorMessage(err: Err, defaultMessage?: string): unknown {
    let message: string | undefined;
    if (err instanceof Error) {
        //@ts-ignore
        if ((err as AxiosError)?.response?.data?.mensagem) {
            //@ts-ignore
            return (err as AxiosError).response!.data.mensagem as string;
        } else {
            message = err.message
        }
    } else if (typeof err == 'string') {
        message = err
    }

    if (message && (/^Network Error/i.test(message) || /^timeout of 30000ms exceeded/i.test(message))) {
        return "Erro de conexão! Verifique se o aparelho está conectado a internet."
    }

    if (message) {
        return message;
    }

    return defaultMessage;
}

export default getFriendlyErrorMessage;