import React from "react";
import { AnunciosListLoader } from "../components/anuncios-list/AnunciosListLoader";
import { ContainerBase } from "containers/ContainerBase";
import { Region } from "components/location/types";

type Props = {
  termoBusca?: string;
  region?: Region;
  anunciosIds?: number[];
  anuncioShowUrl: string;
}

export function AnunciosListContainer(props: Props, railsContext: any) {
  return () => {
    return (
      <ContainerBase railsContext={railsContext}>
        <div>
          <AnunciosListLoader
            termoBusca={props?.termoBusca}
            region={props.region}
            anuncioShowUrl={props.anuncioShowUrl}
          />
        </div>
      </ContainerBase>
    )
  }
}