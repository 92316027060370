import React from "react";
import { ContainerBase } from "containers/ContainerBase";
import { BotaoComprarIngressos } from "../components/botao-comprar-ingressos/BotaoComprarIngressos";

type Props = {
  anuncioId: number;
  paramsIndicacao?: string;
  url: string;
  texto: string;
}
export function BtnComprarContainer(props: Props, railsContext: any) {
  return () => {
    return (
      <ContainerBase railsContext={railsContext}>
        <BotaoComprarIngressos texto={props.texto} anuncioId={props.anuncioId} paramsIndicacao={props.paramsIndicacao} url={props.url}/>
      </ContainerBase>
    )
  }
}