import { useQuery } from "@tanstack/react-query";
import { getFriendlyErrorMessage } from "core/helpers/getFriendlyErrorMessage";
import { getPontosDeVendaDoAnuncio } from "./api/pontosDeVenda";
import { PontoDeVenda } from "./types";


type UsePontosDeVendaResult = {
    data?: PontoDeVenda[];
    isLoading: boolean;
    errorLoading: string | undefined;
    reload(): void;
}

export function usePontosDeVenda(anuncioId: Number): UsePontosDeVendaResult {
    const { data, isLoading, refetch, error } = useQuery({
        queryKey: ['pontos_de_venda', anuncioId],
        queryFn() {
            return getPontosDeVendaDoAnuncio(anuncioId);
        }
    });


    return {
        data: data,
        errorLoading: error ? getFriendlyErrorMessage(error, "Houve um erro ao carregar os pontos de venda") : undefined,
        isLoading: isLoading,
        reload: refetch,
    }
}