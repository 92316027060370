import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

type Props = {};


//função para funcionar a troca de cores ativa do menu lateral de acordo com o scroll
function alterarCorSideMenu() {
  // Seleciona os elementos e os converte para HTMLLIElement e HTMLElement
  const item = Array.from(document.querySelectorAll(".side-menu .item")) as HTMLLIElement[];
  const block = Array.from(document.querySelectorAll('.block')) as HTMLElement[];
  function changeLinkState() {
    let index = block.length - 1;

    while (index >= 0 && window.scrollY + 500 < block[index].offsetTop) {
      index--;
    }

    item.forEach((link) => link.classList.remove('active'));

    if (index >= 0 && index < item.length) {
      item[index].classList.add('active');
    }

  }

  changeLinkState();
  window.addEventListener('scroll', changeLinkState);

  // Função de limpeza
  return () => {
    window.removeEventListener('scroll', changeLinkState);
  };
}

//função para esconder os itens do menu lateral se não existe na tela
function verificarSeExisteNaTela() {
  // Seleciona todos os itens do sub-menu
  const subMenuItems = Array.from(document.querySelectorAll('.side-menu .item .sub-menu a')) as HTMLAnchorElement[];
  const ids = subMenuItems.map((item) => item.getAttribute('href')?.substring(1));

  if (ids.length > 0) {
    // Seleciona todos os elementos com as classes .block e .subtitle
    const blocks = Array.from(document.querySelectorAll('.block, .subtitle')) as HTMLElement[];
    subMenuItems.forEach((item) => {
      const id = item.getAttribute('href')?.substring(1);
      const blockExists = blocks.some((block) => block.id === id);
      const listItem = item.closest('li');
      if (listItem) {
        if (blockExists) {
          listItem.classList.remove('hidden');
        } else {
          listItem.classList.add('hidden');
        }
      }
    });
  }
}

//funções para o anchor menu
function AchorMenu() {
  document.querySelectorAll('li a').forEach(anchor => {
    anchor.addEventListener('click', function(e) {
      e.preventDefault();
  
      const targetId = this.getAttribute('href')?.substring(1);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        const offset = 75;
        const elementPosition = targetElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;
  
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    });
  });
}

export function MenuLateralAnuncio({}: Props): JSX.Element {
  
  useEffect(() => {
    verificarSeExisteNaTela();
    AchorMenu();
    const cleanup = alterarCorSideMenu();
    return cleanup;
  }, []);

  return (
    <ul className="side-menu">
      <li className="item">
        <a href="#informacoes"> <FormattedMessage id="informacoes" /> </a>
        <ul className="sub-menu">
          <li><a href="#informacoes-setores"> <FormattedMessage id="setores_e_areas" /> </a></li>
          <li><a href="#informacoes-atracoes"> <FormattedMessage id="atracoes" /> </a></li>
          <li><a href="#informacoes-importante"> <FormattedMessage id="importante" /> </a></li>
          <li><a href="#informacoes-classificacao"> <FormattedMessage id="classificacao" /> </a></li>
          <li><a href="#pontos-de-venda"> <FormattedMessage id="pontos_de_venda" /> </a></li>
          <li><a href="#informacoes-contato"> <FormattedMessage id="contato" /> </a></li>
        </ul>
      </li>
      <li className="item">
        <a href="#orientacoes"> <FormattedMessage id="orientacoes_gerais" /></a>
      </li>
      <li className="item">
        <a href="#atendimento"> <FormattedMessage id="atendimento" /></a>
      </li>
    </ul>
  );
}
