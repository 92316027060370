import React from "react";

import { getLastSeenAnuncios } from "./helpers/storeLastSeenAnuncios";
import { AnunciosList } from "../anuncios-list/AnunciosList/AnunciosList";
import { useAnuncios } from "../anuncios-list/services/useAnuncios";
import { FormattedMessage } from "react-intl";

type Props = {
  anuncioShowUrl: string;
}

export function LastSeenAnunciosLoader({ anuncioShowUrl }: Props): JSX.Element | null {
  const anunciosIds = getLastSeenAnuncios();

  const { anuncios, isLoading } = useAnuncios({
    AnunciosIdsFilter: anunciosIds
  });

  if (anuncios && anuncios.length > 0) {
    return (
      <div className="eventos ultimos">
        <div className="title">
          <h2 className="line"><FormattedMessage id='vistos_recentemente' /></h2>
          <AnunciosList
            anuncios={anuncios}
            isLoading={isLoading}
            showEmptyTextMessage={false}
            anuncioShowUrl={anuncioShowUrl}
          />
        </div>
      </div>
    )
  }

  return null;
}