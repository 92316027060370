import { Region } from "components/location/types";
import axios from "config/axiosInstance";
import { INGRESSOS_API_URL } from "config/constants";

type GetRegionByCoordinatesParams = {
    latitude: number;
    longitude: number;
}

export async function getRegionByCoordinates({ latitude, longitude }: GetRegionByCoordinatesParams): Promise<Region | undefined> {
    const { data } = await axios.get(`${INGRESSOS_API_URL}/api/v1/geolocalizacao/obter-regiao?latitude=${latitude}&longitude=${longitude}`)

    return data;
}