import { useQuery } from "@tanstack/react-query";
import { AnuncioResumido } from "components/anuncios-list/types"
import { getAnuncios } from "./api/AnunciosApi";
import { getFriendlyErrorMessage } from "core/helpers/getFriendlyErrorMessage";

type UseAnunciosResult = {
    anuncios: AnuncioResumido[] | undefined;
    isLoading: boolean;
    error: string | undefined;
    reload(): void;
}

type UseAnunciosParams = {
    estadoSigla?: string | undefined;
    AnunciosIdsFilter?: number[] | undefined;
    paisCodigo?: string | undefined;
    termoBusca?: string | undefined;
}

export function useAnuncios({ estadoSigla, paisCodigo, AnunciosIdsFilter, termoBusca }: UseAnunciosParams = {}): UseAnunciosResult {
    let anunciosFiltered: AnuncioResumido[] | undefined = []

    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ["anuncios", estadoSigla || 'todos', paisCodigo || 'todos'],
        queryFn: () => getAnuncios({
            estadoSigla: estadoSigla,
            paisCodigo: paisCodigo,
            termoBusca
        }),
    });

    anunciosFiltered = data?.anuncios;

    if (AnunciosIdsFilter){
      anunciosFiltered = anunciosFiltered?.filter((anuncio)=> AnunciosIdsFilter.includes(anuncio.id))
    }

    return {
        anuncios: anunciosFiltered,
        isLoading,
        error: error ? getFriendlyErrorMessage(error, "Houve um erro ao carregar os dados") : undefined,
        reload: refetch
    }
}