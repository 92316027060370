import React from "react";
import { ContainerBase } from "./ContainerBase";
import { LastSeenAnunciosLoader } from "components/last-seen-anuncios/LastSeenAnunciosLoader";

type Props = {
    anuncioShowUrl: string;
}


export function LastSeenAnunciosContainer(props: any, railsContext: any) {
    return () => {
        return (
            <ContainerBase railsContext={railsContext}>
                <LastSeenAnunciosLoader anuncioShowUrl={props.anuncioShowUrl} />
            </ContainerBase>
        )
    }
}