import React from "react";
import { AutenticationNavbar } from "../components/autentication-navbar/AutenticationNavbar/AutenticationNavbar";
import { ContainerBase } from "containers/ContainerBase";




export function AutenticationNavBarContainer(props: any, railsContext: any) {
    return () => {
        return (
            <ContainerBase railsContext={railsContext}>
                <AutenticationNavbar registerUrl={props.registerUrl} myAccountUrl={props.myAccountUrl} myTicketsUrl={props.myTicketsUrl} />
            </ContainerBase>
        );
    }
}