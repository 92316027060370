import axios from "axios";
import { Banner, BannersCarouselApi } from "../../../../../../baladapp-react-components/src/banners-carousel/types";
import { INGRESSOS_API_URL } from "config/constants";

export default function (): BannersCarouselApi {
    return {
        fetchBanners
    }
}


export async function fetchBanners(): Promise<Banner[]> {
    const { data } = await axios.get(`${INGRESSOS_API_URL}/api/v3/banners`);

    return data?.banners ?? [];
}
