import React from "react";

//@ts-ignore
import Icons from "../../../assets/images/icons.svg";

// import classes from "./autentication-navbar.module.scss";
import { FormattedMessage } from "react-intl";
import { useAuthenticatedUser } from "core/services/authentication";
import { ACCOUNT_URL, AUTH2_URL } from "config/constants";

type Props = {
    registerUrl: string,
    myAccountUrl: string,
    myTicketsUrl: string
}

export function AutenticationNavbar({ registerUrl, myAccountUrl, myTicketsUrl }: Props): JSX.Element {
    const { authenticatedUser } = useAuthenticatedUser();

    // const followUrl = btoa(window.location.href);
    // const registerUrl = `${AUTH2_URL}`

    // const myTicketsUrl = `${ACCOUNT_URL}/anuncios`;

    if (authenticatedUser) {
        return (
            <div className="acesso">
                <a className="login" href={`${myAccountUrl}`}>
                    <svg>
                        <use href={`${Icons}#icon-user`}></use>
                    </svg>
                    <FormattedMessage id="ola" /> {authenticatedUser.nome}
                </a>
                <a className="cadastrar" href={myTicketsUrl}><FormattedMessage id="meus-ingressos" /></a>
                {/*  Tem que colocar as iniciais do usuario aqui */}
                <a className="icon-mobile" href={`${myAccountUrl}`}>
                    <svg>
                        <use href={`${Icons}#icon-account`}></use>
                    </svg>
                </a>
            </div>
        )
    }

    return (
        <div className="acesso">
            <a className="login" href={myAccountUrl}><FormattedMessage id="minha_conta" /></a>
            <a className="cadastrar" href={registerUrl}><FormattedMessage id="cadastrar" /></a>
            <a className="icon-mobile" href={registerUrl}>
                <svg>
                    <use href={`${Icons}#icon-account`}></use>
                </svg>
            </a>
        </div>
    )
}