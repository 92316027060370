import React, { useState } from "react";
import { useIntl, FormattedMessage } from 'react-intl';

//@ts-ignore
import Icons from "../../../assets/images/icons.svg";
import './search-bar.scss';

type Props = {
    termoBusca?: string;
    style?: string;
}

export function SearchBar({ termoBusca = '', style = 'desktop' }: Props): JSX.Element {
    const [searchTerm, setSearchTermo] = useState<string>(termoBusca ?? '');

    const onChangeText = (event: any) => {
        setSearchTermo(event.target.value);
    }

    const search = () => {
        const currentUrl = new URL(window.location.href);

        console.log(currentUrl);

        if (searchTerm && searchTerm.trim().length > 0) {
            currentUrl.searchParams.set("search", searchTerm);
        } else {
            currentUrl.searchParams.delete("search");
        }

        window.location.href = currentUrl.toString();
    }

    const fecharModalBuscaNoMobile = () => {
        const searchMobileElement = document.getElementById("search-mobile");
        if (searchMobileElement) {
            searchMobileElement.style.display = "none";
        }
    }

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            search();
        }
    };

    const intl = useIntl();


    const styleDesktop = (
        <div id='search-bar-component' className="campo">
            <svg onClick={search}>
                <use href={`${Icons}#lupa`} />
            </svg>

            <input
                type='text'
                // placeholder="Digite o evento que procura"
                placeholder={intl.formatMessage({ id: 'digite_evento_busca' })}
                value={searchTerm}
                onChange={onChangeText}
                onKeyDown={handleKeyPress}
            />
        </div>
    );

    const styleMobile = (

        <div className="modal-busca">
            <a className="voltar">
                <svg onClick={fecharModalBuscaNoMobile}>
                    <use href={`${Icons}#voltar`} />
                </svg>
            </a>
            <input
                type='text'
                placeholder="Digite o evento que procura"
                value={searchTerm}
                id="input-mobile"
                onChange={onChangeText}
                onKeyDown={handleKeyPress}
            />
            <button type="submit">
                <svg onClick={search}>
                    <use href={`${Icons}#lupa-mobile`} />
                </svg>
            </button>
        </div>

    );

    if (style == 'desktop') {
        return styleDesktop;
    } else if (style == 'mobile') {
        return styleMobile;
    } else {
        return <></>
    }
}