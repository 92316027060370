import React, { useState } from "react";

import { flushExpired, set, get } from 'lscache';

type Props = {
  anuncioId: number;
  paramsIndicacao?: string;
  url: string;
  texto: string;
}

export function BotaoComprarIngressos(props: Props): JSX.Element {
  // remove storage expiradas
  flushExpired();

  let nomeStorage = `cod_indicacao_${props.anuncioId}`

  if (props.paramsIndicacao) {
    set(nomeStorage, props.paramsIndicacao, 24 * 60)
  }

  const url = new URL(props.url)

  if (!!get(nomeStorage) && !props.paramsIndicacao) {
    url.searchParams.set('indicacao', get(nomeStorage))
  }

  return (
    <a className="btn-comprar" href={url.toString()} >{props.texto}</a>
  )
}