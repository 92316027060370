import React from "react";
import Modal from "react-modal";
import { FormattedMessage } from "react-intl";

//@ts-ignore
import Icons from "../../assets/images/icons.svg"
import { usePontosDeVenda } from "components/botao-ponto-de-vendas/usePontosDeVenda";

type Props = {
  isOpen: boolean;
  onRequestClose?(): any;
  anuncioId: Number
}

//https://reactcommunity.org/react-modal/styles/
const customStyles = {
  content: {
    width: '50%',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
  }
}

export function PontoDeVendasModal({
  isOpen,
  onRequestClose,
  anuncioId
}: Props): JSX.Element {


  const { isLoading, data, errorLoading, reload } = usePontosDeVenda(anuncioId);

  let renderList = () => {
    return (
      <div className="pdv-lista">
        {data?.map((pdv) => {
          return (
            <div className="pdv-item" key={pdv.id}>
              <div className="info">
                <b>{pdv.nome}</b>
                <span>{pdv.endereco_completo}</span>
              </div>
              <a className="icon" href={pdv.mapa_url} target="_blank">
                <svg>
                  <use href={`${Icons}#pdv`}></use>
                </svg>
                <span><FormattedMessage id="ver_no_mapa" /></span>
              </a>
            </div>
          )
        })}

      </div>
    )
  }

  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      className={"modal-info"}
    >
      <div className="baladapp-modal-texto">
        <div className="title">
          <h2><FormattedMessage id="pontos_de_venda" /></h2>
          <a className="fechar" onClick={onRequestClose}>
            <svg>
              <use href={`${Icons}#close`}></use>
            </svg>
          </a>
        </div>

        <div className="body">
          {isLoading && (
            <div>Loading</div>
          )}

          {!isLoading && renderList()}
        </div>

      </div>


    </Modal>
  )
}