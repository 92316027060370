export const INGRESSOS_API_URL = MULTI_EMPRESA_API_URL as string;
export const AUTH_URL = MULTI_EMPRESA_AUTH_URL as string;
export const AUTH2_URL = MULTI_EMPRESA_AUTH2_URL as string;
export const ACCOUNT_URL = MULTI_EMPRESA_ACCOUNT_URL as string;
export const CHECKOUT_URL = MULTI_EMPRESA_CHECKOUT_URL as string;

export const JWT_COOKIE_NAME = ENVIRONMENT == 'production' ? 'baladapp_jwt_token' : `baladapp_jwt_token_${ENVIRONMENT}` as string;
export const COOKIES_DOMAIN = MULTI_EMPRESA_COOKIES_DOMAIN as string;
export const COOKIES_SECURE = ENVIRONMENT == 'production' ? true : false;
export const COOKIES_SAMESITE = 'Lax';
export const USAGE_LVL_COOKIE_NAME = 'nivel_de_uso_de_cookies'
export const SITE_DURACAO_SESSAO_EM_HORAS = 2;
