import React from "react";
import { ContainerBase } from "containers/ContainerBase";
import BannerCarouselApi from "components/banners/services/api/BannerCarouselApi";
import { BannersCarouselContainer } from "../../../baladapp-react-components/src/banners-carousel/containers/BannersCarouselContainer";

export function BannersContainer(props: any, railsContext: any) {
    const api = BannerCarouselApi();
    
    return () => {
        return (
            <ContainerBase railsContext={railsContext}>
                <BannersCarouselContainer api={api}></BannersCarouselContainer>
            </ContainerBase>
        )
    }
}