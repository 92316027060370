import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { COOKIES_DOMAIN, USAGE_LVL_COOKIE_NAME, COOKIES_SAMESITE, COOKIES_SECURE } from "config/constants";
import CookiesLib from "js-cookie";
import "./cookies.scss"

//@ts-ignore
import Icons from "../../assets/images/icons.svg"

type Props = {
  AuthUrl?: string;
}

export function Cookies({ AuthUrl }: Props) {
  const [nivelCookieSelected, setNivelCookieSelected] = useState<number>(1);
  const [showCookieDiv, setShowCookieDiv] = useState<boolean>(!CookiesLib.get(USAGE_LVL_COOKIE_NAME));

  const intl = useIntl();
  const salvarPreferenciasText = intl.formatMessage({ id: 'salvar_preferencias' });

  function abrirConfiguraçõesCookies() {
    const defaultCookiesElement = document.getElementById('default-cookies');
    const formCookiesElement = document.getElementById('form-cookies');

    if (defaultCookiesElement) {
      defaultCookiesElement.style.display = 'none';
    }

    if (formCookiesElement) {
      formCookiesElement.style.display = 'block';
    }
  }

  const selecionouPreferenciaCookie = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNivelCookieSelected(parseInt(event.target.value));
  }

  function salvarPreferenciasCookies() {
    console.log('USAGE_LVL_COOKIE_NAME', USAGE_LVL_COOKIE_NAME)
    console.log('COOKIES_DOMAIN', COOKIES_DOMAIN)
    console.log('COOKIES_SECURE', COOKIES_SECURE)
    console.log('COOKIES_SAMESITE', COOKIES_SAMESITE)

    CookiesLib.set(USAGE_LVL_COOKIE_NAME, nivelCookieSelected.toString(), { domain: COOKIES_DOMAIN, secure: COOKIES_SECURE, samesite: COOKIES_SAMESITE, expires: 999 })

    setShowCookieDiv(false);
  }

  return (
    showCookieDiv && (
      <div className="card-cookies">
        <div id="default-cookies">
          <div className="text">
            <div className="title">
              <FormattedMessage id="consetimento_cookie" />
            </div>
            <div className="info">
              <span><FormattedMessage id="otimizar_navegacao_cookie" /></span>
              <p>
                <FormattedMessage id="navegacao_cookie" />&nbsp;
                <a className="link" href={`${AuthUrl}/politicas/privacidade`}><FormattedMessage id="politica_privacidade" /> </a>
              </p>
            </div>
          </div>
          <div className="buttons">
            <a onClick={abrirConfiguraçõesCookies}>
              <svg>
                <use href={`${Icons}#slider-settings`}></use>
              </svg>
              <FormattedMessage id="preferencias" /> </a>
            <a onClick={salvarPreferenciasCookies}>
              <FormattedMessage id="entendi" />
            </a>
          </div>
        </div>
        <form id="form-cookies">
          <div className="options">
            <div className="text">
              <div className="title">
                <FormattedMessage id="config_cookies" />
              </div>
            </div>
            <label>
              <input type="radio" className="option-input checkbox" value={1} name="opcaoCookie" onChange={selecionouPreferenciaCookie} defaultChecked />
              <span className="title-radio"><FormattedMessage id="uso_dispositivo_pessoal" /></span>
              <span className="description-radio"><FormattedMessage id="descricao_dispositivo_pessoal" /></span>
            </label>
            <label>
              <input type="radio" className="option-input checkbox" value={2} onChange={selecionouPreferenciaCookie} name="opcaoCookie" />
              <span className="title-radio"><FormattedMessage id="uso_dispositivo_terceiros" /></span>
              <span className="description-radio"><FormattedMessage id="descricao_dispositivo_terceiros" /></span>
            </label>

            <span className="footer-text">
              <FormattedMessage id="conheca_nossa" />&nbsp;
              <a className="link" href={`${AuthUrl}/politicas/privacidade`}>
                <FormattedMessage id="politica_privacidade" />&nbsp;
              </a>
              <FormattedMessage id="e" />&nbsp;
              <a className="link" href={`${AuthUrl}/politicas/uso`}>
                <FormattedMessage id="termos_de_uso" />
              </a>
              .
            </span>

            <input className="saveConfig" type="button" onClick={salvarPreferenciasCookies} value={salvarPreferenciasText}>
            </input>
          </div>
        </form>
      </div>
    )
  )
}

