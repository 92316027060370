import React from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import queryClient from "config/queryClient";

import { IntlProvider } from "i18n";

type Props = {
    railsContext: any;
    children: JSX.Element | JSX.Element[];
}

export function ContainerBase({ children, railsContext }: Props): JSX.Element {
    return (
        <IntlProvider railsContext={railsContext}>
            <QueryClientProvider client={queryClient}>
                {children}
            </QueryClientProvider>
        </IntlProvider>
    )
}