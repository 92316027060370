


import { PontoDeVenda } from "../types";

import axios from "config/axiosInstance";
import { INGRESSOS_API_URL } from "config/constants";


export async function getPontosDeVendaDoAnuncio(anuncioId: Number): Promise<PontoDeVenda[]> {
    const { data } = await axios.get(`${INGRESSOS_API_URL}/api/v3/anuncios/${anuncioId}/pontos-de-venda`);

    return data ?? [];
}