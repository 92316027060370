import React, { useState } from "react";

import { AcessibilidadeModal } from "../acessibilidade-modal/AcessibilidadeModal";
import { FormattedMessage } from "react-intl";

//@ts-ignore
import Icons from "../../assets/images/icons.svg";

type Props = {

}

export function BotaoAcessibilidade({

}: Props): JSX.Element {
  const [informationModalOpen, setInformationModalOpen] = useState<boolean>(false);

  function abrirModalInformation() {
    setInformationModalOpen(true);
  }

  function fecharModalInformation() {
    setInformationModalOpen(false);
  }

  return (
    <>
      <span className="link-button" onClick={abrirModalInformation}>
        <svg>
          <use href={`${Icons}#law`}></use>
        </svg>
        <span><FormattedMessage id="lei_acessibilidade_am" /> </span>
      </span>
      
      <AcessibilidadeModal
        isOpen={informationModalOpen}
        onRequestClose={fecharModalInformation}
      />

    </>
  )
}