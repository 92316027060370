import React from "react";
import { AnuncioListHotsiteLoader } from "../components/anuncios-list/AnuncioListHotsiteLoader";
import { ContainerBase } from "containers/ContainerBase";

type Props = {
  anunciosIds?: number[];
  anuncioShowUrl: string;
}

export function AnunciosListHotsiteContainer(props: Props, railsContext: any) {
  return () => {
    return (
      <ContainerBase railsContext={railsContext}>
        <div>
          <AnuncioListHotsiteLoader
            anunciosIds={props.anunciosIds}
            anuncioShowUrl={props.anuncioShowUrl}
          />
        </div>
      </ContainerBase>
    )
  }
}