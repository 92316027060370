import React, { useEffect, useState } from "react";
import { useAnuncios } from "./services/useAnuncios";
import { AnunciosList } from "./AnunciosList";
import { Region } from "components/location/types";
import { removeLastSeenAnuncios } from "../last-seen-anuncios/helpers/storeLastSeenAnuncios";
import { AnuncioResumido } from "components/anuncios-list/types"

type Props = {
  anunciosIds?: number[];
  anuncioShowUrl: string;
}

export function AnuncioListHotsiteLoader({ anunciosIds, anuncioShowUrl }: Props) {
  console.log('anuncios', anunciosIds)
  const { anuncios, isLoading } = useAnuncios({
    AnunciosIdsFilter: anunciosIds
  });

  return (
    <AnunciosList
    anuncios={anuncios}
    isLoading={isLoading}
    anuncioShowUrl={anuncioShowUrl}
  />
  )
}