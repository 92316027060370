import React from "react";
import { IntlProvider as IntlProviderRI } from "react-intl";
//@ts-ignore
import { translations } from "./translations";

type Props = {
    children: JSX.Element | JSX.Element[];
    railsContext: Record<string, any>;
}


function getLocale(locale: string, fallback = "pt-BR") {
    return translations[locale] || translations[fallback];
}

export function IntlProvider({ children, railsContext }: Props) {
    const locale = railsContext.i18nLocale as string || "pt-BR";
    const defaultLocale = railsContext.i18nDefaultLocale as string || "pt-BR";

    const messages = getLocale(locale);

    return (
        <IntlProviderRI
            locale={locale}
            defaultLocale={defaultLocale}
            messages={messages}
        >
            {children}
        </IntlProviderRI>
    )
}