import { AddLastSeenAnuncio } from "components/last-seen-anuncios/AddLastSeenAnuncio";
import React from "react";
import { ContainerBase } from "./ContainerBase";

type Props = {
    anuncioId?: number;
}

export function AddLastSeenAnuncioContainer(props: Props, railsContext: any) {
    return () => {
        return (
            <ContainerBase railsContext={railsContext}>
                <AddLastSeenAnuncio anuncioId={props.anuncioId} />
            </ContainerBase>
        )
    }
}