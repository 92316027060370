import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import "./banners-carousel.scss";

// import { useBanners } from "../services/useBanners";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { Banner, BannersCarouselApi } from "../types";
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useBanners } from "../hooks/useBanners";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function removeBackgroundSite() {
  const bodyBackground = document.querySelector('.body-background') as HTMLElement;
  if (bodyBackground) {
    bodyBackground.style.background = 'none';
  }
}


type BannersCarouselProps = {
  api: BannersCarouselApi
}

export function BannersCarouselContainer({ api }: BannersCarouselProps): JSX.Element {
  const { isLoadingBanners, banners, errorLoadingBanners, reloadBanners } = useBanners({ api: api });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (banners && banners.length === 0) {
      removeBackgroundSite();
    }
  }, [banners]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    handleResize(); // Atualiza o tamanho ao montar o componente

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isLoadingBanners) {
    return (
      <div className="swiper loading">
        <Skeleton height={'100%'} />
      </div>
    )
  }

  if (!banners || banners.length == 0) {
    return (
      <div className="empty-banner"></div>
    )
  }

  const onClick = (banner: Banner) => {
    if (banner.url) {
      window.location.href = banner.url;
    }
  }

  return (
    <Carousel
      responsive={responsive}
      className="swiper"
      autoPlay
      shouldResetAutoplay
      infinite={true}
    >
      {banners?.map((banner) => {
        return (
          <a onClick={() => onClick(banner)} key={banner.id}>
            <img src={windowWidth >= 600 ? banner.arquivo_url : banner.arquivo_4x_url} />
          </a>
        );
      })}
    </Carousel>
  )
}