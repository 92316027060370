import Cookies from "js-cookie";

import * as Constants from "../../../config/constants";
import { AuthenticatedUser } from "./types";
import { addDays } from "date-fns";

export function clearAuthenticationToken() {
    Cookies.remove(Constants.JWT_COOKIE_NAME, { domain: Constants.COOKIES_DOMAIN, secure: Constants.COOKIES_SECURE, samesite: Constants.COOKIES_SAMESITE });
    Cookies.remove('_baladapp_auth_session', { domain: Constants.COOKIES_DOMAIN, secure: Constants.COOKIES_SECURE, samesite: Constants.COOKIES_SAMESITE });
}

export function getAuthenticationToken() {
    const token = Cookies.get(Constants.JWT_COOKIE_NAME);
    return token;
}

export function saveAuthenticationToken(token: string) {
    const durationSession = Constants.SITE_DURACAO_SESSAO_EM_HORAS * 60 * 60 * 1000
    const expires = new Date(new Date().getTime() + durationSession);
    Cookies.set(Constants.JWT_COOKIE_NAME, token, { domain: Constants.COOKIES_DOMAIN, secure: Constants.COOKIES_SECURE, samesite: Constants.COOKIES_SAMESITE, expires })
}

export function getAuthenticatedUser(): undefined | AuthenticatedUser {
    const token = getAuthenticationToken();
    if (token) {
        const parsed = parseJwt(token);
        const usuario = parsed?.usuario;

        if (usuario) {
            return usuario as AuthenticatedUser;
        }
    }

    return undefined;
}

export function isAuthed(): boolean {
    const token = getAuthenticationToken();
    if (token) {
        const parsedToken = parseJwt(token);
        if (!parsedToken) {
            return false;
        }

        return Math.round(new Date().getTime() / 1000) <= parsedToken.exp
    }
    return false;
}

function parseJwt(token: string) {
    try {
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace('-', '+').replace('_', '/')
        const parsed = JSON.parse(decodeURIComponent(escape(window.atob(base64))))
        return parsed
    } catch (e) {
        return {}
    }
}

function makeId() {
    var text = ''
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (var i = 0; i < 32; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length))

    return text
}

function expirationNextYear() {
    return addDays(new Date(), 300);
}

export function getDeviceId() {
    if (!Cookies.get('dev')) {
        Cookies.set('dev', makeId(), { path: '/', domain: Constants.COOKIES_DOMAIN, secure: Constants.COOKIES_SECURE, expires: expirationNextYear() })
    }
    return Cookies.get('dev');
}