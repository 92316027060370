import React, { useEffect, useState } from "react";

//@ts-ignore
import Icons from "../../../assets/images/icons.svg";
import LocationPickerModal from "../LocationPickerModal/LocationPickerModal";

import "./location-indicator.scss";
import { useCurrentLocation } from "../services/useCurrentLocation";
import { FormattedMessage } from "react-intl";
import { Pais, Region } from "../types";

type Props = {
    region?: Region;
    paises: Pais[];
}

const LOCATION_TOOLTIP_SEEN = "LOCATION_TOOLTIP_SEEN";

export function LocationIndicator({ region, paises }: Props) {
    const [locationModalOpen, setLocationModalOpen] = useState<boolean>(false);
    // const [toolTipOpen, setTooltipOpen] = useState<boolean>(!sessionStorage.getItem(LOCATION_TOOLTIP_SEEN));
    const [toolTipOpen, setTooltipOpen] = useState<boolean>(false);
    const { getCurrentPosition, regionCurrentLocation, selectedRegion, selectRegionAndSave } = useCurrentLocation();

    function abrirModalLocalizacao() {
        fecharTooltip();
        setLocationModalOpen(true);
    }

    function fecharModalLocalizacao() {
        setLocationModalOpen(false);
    }

    function fecharTooltip() {
        setTooltipOpen(false);
        sessionStorage.setItem(LOCATION_TOOLTIP_SEEN, "true");
    }

    useEffect(() => {
        // getCurrentPosition();
    }, []);

    const goToUrl = ({ codigo, sigla }: { codigo: string; sigla?: string }) => {
        if (codigo && sigla) {
            window.location.href = `/eventos/${codigo.toLowerCase()}/${sigla.toLowerCase()}`
        } else if (codigo) {
            window.location.href = `/eventos/${codigo.toLowerCase()}`
        }
    }

    const onContinuar = () => {
        if (selectedRegion) {
            selectRegionAndSave(selectedRegion);
            const codigo = selectedRegion.codigo;
            const sigla = selectedRegion.estado?.sigla;

            goToUrl({ codigo, sigla });
        } else if (regionCurrentLocation) {
            selectRegionAndSave(regionCurrentLocation);
            const codigo = regionCurrentLocation.codigo;
            const sigla = regionCurrentLocation.estado?.sigla;

            goToUrl({ codigo, sigla });
        }

        fecharTooltip();
    }

    const onSelectRegion = (region: Region | undefined) => {
        selectRegionAndSave(region);

        if (region) {
            const codigo = region.codigo;
            const sigla = region.estado?.sigla;

            goToUrl({ codigo, sigla });
        } else {
            window.location.href = "/";
        }

        fecharTooltip();
    }

    let labelSelectedRegion: string | undefined = undefined;
    if (selectedRegion?.estado?.nome_com_pais) {
        labelSelectedRegion = selectedRegion.estado.nome_com_pais;
    } else if (selectedRegion?.nome) {
        labelSelectedRegion = selectedRegion.nome;
    } else {
        labelSelectedRegion = regionCurrentLocation?.estado?.nome_com_pais;
    }

    return (
        <div id="location-indicator">
            <div className="localizacao">
                <a className="trocar-cidade" onClick={abrirModalLocalizacao}>
                    <svg>
                        <use href={`${Icons}#map`} />
                    </svg>
                    {!!region ? (
                        <p>{region.estado?.nome_com_pais ?? region.nome}</p>
                    ) : <p><FormattedMessage id='qualquer_lugar' /></p>}
                </a>
                {toolTipOpen && (
                    <div className="alerta">
                        <div className="ballon">
                            {!!labelSelectedRegion ? (
                                <>
                                    <p><FormattedMessage id='voce_esta_em' /></p>
                                    <p className="cidade">
                                        {labelSelectedRegion}
                                    </p>
                                </>
                            ) :
                                <>
                                    <p><FormattedMessage id='voce_esta_em' /></p>
                                    <p className="cidade">
                                        <FormattedMessage id='qualquer_lugar' />
                                    </p>
                                </>
                            }

                            <a className="continuar" onClick={onContinuar}><FormattedMessage id='continuar' /></a>
                            <a className="trocar-cidade" onClick={() => {
                                fecharTooltip();
                                abrirModalLocalizacao();
                            }}><FormattedMessage id='alterar' /></a>
                        </div>
                    </div>
                )}

            </div>
            <LocationPickerModal
                isOpen={locationModalOpen}
                onRequestClose={fecharModalLocalizacao}
                selectRegion={onSelectRegion}
                getCurrentPosition={getCurrentPosition}
                paises={paises}
                currentRegion={region}
            />
        </div>

    );
}