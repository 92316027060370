import React from "react";
import { ContainerBase } from "containers/ContainerBase";
import { BotaoPontoDeVendas } from "../components/botao-ponto-de-vendas/BotaoPontoDeVendas";


type Props = {
    anuncioId?: number;
}
export function BotaoPontoDeVendasContainer(props: any, railsContext: any) {
    return () => {
        return (
            <ContainerBase railsContext={railsContext}>
                <BotaoPontoDeVendas anuncioId={props.anuncioId} />
            </ContainerBase>
        )
    }
}