import qs from "qs";

import axios from "config/axiosInstance";

import { AnuncioResumido } from "../../types"
import { INGRESSOS_API_URL } from "config/constants";

type GetAnunciosResult = {
    anuncios: AnuncioResumido[];
}

type GetAnunciosParams = {
    estadoSigla?: string;
    paisCodigo?: string;
    termoBusca?: string;
    anunciosIds?: number[];
}

export async function getAnuncios({ estadoSigla, paisCodigo, termoBusca, anunciosIds }: GetAnunciosParams = {}): Promise<GetAnunciosResult> {
    const query = qs.stringify({
        estado_sigla: estadoSigla,
        pais_codigo: paisCodigo,
        termo: termoBusca,
        anuncios_ids: anunciosIds,
    }, {
        arrayFormat: 'brackets',
    });

    const { data } = await axios.get(`${INGRESSOS_API_URL}/api/v3/anuncios?${query}`);

    return data;
}