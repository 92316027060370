import { addDays } from "date-fns";
import { Region } from "../types"
import * as LocalStorageWithExpiration from "../../../core/helpers/localStorageWithExpiration";


const SELECTED_REGION_KEY = 'BALADAPP_SELECTED_REGION';

export function getSelectedRegionFromStorage(): Region | undefined{
    try {
        const region = LocalStorageWithExpiration.getItem(SELECTED_REGION_KEY);
        if(region) {
            return JSON.parse(region);
        }
    } catch(err) {
        console.error("Não foi possivel recuperar a regiao selecionada salva", err);
    }

    return undefined;
}

type SaveSelectedRegionIntoStorageParams = {
    region: Region;
}

export function saveSelectedRegionIntoStorage({ region }: SaveSelectedRegionIntoStorageParams) {
    try {
        LocalStorageWithExpiration.setItem(SELECTED_REGION_KEY, JSON.stringify(region), { expirationDate: addDays(new Date(), 7)});
    }catch(err) {
        console.error("Não foi possível salvar a regiao selecionada", err);
    }
}

export function removeSelectedRegionFromStorage() {
    try {
        localStorage.removeItem(SELECTED_REGION_KEY);
    }catch(err) {
        console.error("Não foi possível remover a regiao", err);
    }
}