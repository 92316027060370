import Carrinho from "../../../../types/carrinho";
import { VitrineFormatada } from "../../../../types/vitrine";

const get = require("lodash.get");

type Result = {
    carrinho: Carrinho;
    changed: boolean;
}

//retorna carrinho apenas com itens que existem na vitrine
export default function removerCarrinhoItensInexistentesVitrine(vitrine: VitrineFormatada, carrinho: Carrinho): Result {
    let changed = false;
    const novoCarrinho: Carrinho = {
        ...carrinho,
        carrinho_itens: carrinho.carrinho_itens.filter((item) => {
            const anuncioOpcao = get(vitrine, ['sessoes', item.sessao_id, 'tipo_opcoes', item.tipo_opcao_id, 'anuncio_opcoes', item.anuncio_opcao_id]);

            if(anuncioOpcao) {
                return true;
            }
            changed = true;
            return false;
        })
    };

    return {
        carrinho: novoCarrinho,
        changed
    }
}